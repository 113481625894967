import { WebRequest, WebRequestError, WebResponse } from '../../../../types/shared/web';
import { isTrue } from '../../../helpers/value_helpers';
import { fakeFetch, handleFakeError, handleFakeReturn } from './fake_fetch';

const { REACT_APP_FAKE_FETCH } = process.env;

export abstract class WebService<TRequest extends WebRequest> {
    private fetcher: (url: string, options: any) => Promise<Response> = null;
    private returnHandler: <TBody extends any = {}>(
        url: string,
        request: TRequest,
        response: Response
    ) => Promise<WebResponse<TBody>> = null;
    private errorHandler: (error: any, url: string) => any;

    constructor() {
        const useFakeFetch = isTrue(REACT_APP_FAKE_FETCH);

        this.fetcher = useFakeFetch ? (url, options) => fakeFetch(url, options) : (url, options) => fetch(url, options);
        this.returnHandler = useFakeFetch ? handleFakeReturn : this.handleReturn;
        this.errorHandler = useFakeFetch
            ? (error, url) => handleFakeError(error, url)
            : (error, url) => this.handleError(error, url);
    }

    protected abstract buildUrl(request: TRequest): string;

    public async get<TBody extends any = {}>(request: TRequest): Promise<WebResponse<TBody>> {
        return await this.call(request, 'GET');
    }

    public async post<TBody extends any = {}>(request: TRequest): Promise<WebResponse<TBody>> {
        return await this.call(request, 'POST');
    }

    public async put<TBody extends any = {}>(request: TRequest): Promise<WebResponse<TBody>> {
        return await this.call(request, 'PUT');
    }

    public async patch<TBody extends any = {}>(request: TRequest): Promise<WebResponse<TBody>> {
        return await this.call(request, 'PATCH');
    }

    public async delete<TBody extends any = {}>(request: TRequest): Promise<WebResponse<TBody>> {
        return await this.call(request, 'DELETE');
    }

    protected buildHeader(request: TRequest) {
        const header = {
            headers: {
                accept: '*/*',
                'content-type': 'application/json'
            }
        };

        return header;
    }

    protected call<TBody extends any = {}>(request: TRequest, method: string): Promise<WebResponse<TBody>> {
        const options = {
            ...this.buildHeader(request),
            ...this.getOptions(request),
            credentials: 'include',
            method
        };

        const url = this.buildUrl(request);
        const result = this.fetcher(url, options)
            .then(result => {
                return this.returnHandler<TBody>(url, request, result);
            })
            .catch(error => {
                return this.errorHandler(error, url);
            });

        return result;
    }

    protected getOptions(request: TRequest): any {
        const options: { body?: string; insecureHTTPParser: boolean } = {
            insecureHTTPParser: true
        };

        if (request.body) {
            options.body = JSON.stringify(request.body);
        }

        return options;
    }

    protected async handleReturn<TBody extends any = {}>(
        url: string,
        request: TRequest,
        response: Response
    ): Promise<WebResponse<TBody>> {
        if (!response.ok) {
            const errorBody = await response.text();
            throw new WebRequestError(url, errorBody, response.status);
        }

        if (response.body == null) {
            return {
                success: true,
                status_code: response.status,
                messages: []
            };
        }

        const responsePackage = response.status == 200 ? await response.json() : {};

        return {
            success: true,
            status_code: response.status,
            body: responsePackage?.responseBody ?? responsePackage,
            messages: responsePackage.messages ?? []
        };
    }

    protected handleError(error: any, url: string): any {
        throw WebRequestError.fromWebError(error, url);
    }
}
