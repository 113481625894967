import { OrdersRequest } from '../../../types/api/request/orders/request';
import { Order } from '../../../types/api/response/orders/order';
import { WebRequest, WebResponse } from '../../../types/shared/web';
import { apiPaths, apiWebService } from '../web/http/api';

export const getOrders = async (requestQuery?: OrdersRequest): Promise<WebResponse<Array<Order>>> => {
    const request: WebRequest = {
        path: apiPaths.orders.get,
        query: requestQuery
    };

    return apiWebService.get<Array<Order>>(request);
};
