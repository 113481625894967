export const version: string = '0.1.0';
export const navbarBreakPoint: 'xl' = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint: 'lg' = 'lg';
export const settings = {
    isFluid: false,
    isRTL: false,
    isDark: false,
    navbarPosition: 'vertical',
    showBurgerMenu: false, // controls showing vertical nav on mobile
    currency: '$',
    isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
    navbarStyle: 'transparent',
    showSettingPanel: false,
    navbarCollapsed: false
};
const appSettings = {
    version,
    navbarBreakPoint,
    topNavbarBreakpoint,
    settings
};
export default appSettings;
