import { combineReducers } from 'redux';
import { accountReducers } from './account/reducers';
import { ordersRedux } from './fetch/orders';
import { organizationRedux } from './fetch/organization';
import { storeRedux } from './fetch/store';
import { topSellingProductsRedux } from './fetch/top_selling/products';
import { pageSettingsReducer } from './page/reducers';
import { RootState } from './root_state';

const rootReducer = combineReducers<RootState>({
    pageSettingsState: pageSettingsReducer,
    sessionState: accountReducers,
    organizationState: organizationRedux.createReducer(),
    ordersState: ordersRedux.createReducer(),
    topSellingProductsState: topSellingProductsRedux.createReducer(),
    storeState: storeRedux.createReducer()
});

export default rootReducer;
