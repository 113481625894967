import BasicCard from '../../../components/Common/BasicCard';
import PageHeader from '../../../components/Common/PageHeader';
import { PageContent } from '../../../types/content/page';

const content: PageContent = {
    path: '/about',
    components: [
        {
            component: PageHeader,
            properties: {
                image: '',
                preTitle: '',
                title: 'About',
                description: 'This page is coming soon...I promise'
            }
        },
        {
            component: BasicCard,
            properties: {
                title: 'Item 1',
                description: 'Item 1 description'
            }
        }
    ]
};

export default content;
