import classNames from 'classnames';
import { FC } from 'react';
import IconItem, { IconItemProps } from './IconItem';

export interface IconGroupProps {
    icons: any;
    className: string;
}

const IconGroup: FC<IconGroupProps> = function IconGroup({ icons, className, ...rest }) {
    return (
        <div className={classNames('icon-group', className)} {...rest}>
            {icons.map((icon: JSX.IntrinsicAttributes & IconItemProps) => (
                <IconItem {...icon} key={icon.color} />
            ))}
        </div>
    );
};

export default IconGroup;
