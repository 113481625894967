import { FC } from 'react';
import { Card } from 'react-bootstrap';
import { BasicComponetProps } from '../../types/react/basic_component_props';

interface BasicCardProperties extends BasicComponetProps {
    paragraphClass: string;
    ref?: React.ForwardedRef<any>;
}

const BasicCard: FC<BasicCardProperties> = (props: BasicCardProperties) => {
    return (
        <Card className='mb-3' id={props.id} ref={props.ref}>
            <Card.Header className='bg-light'>
                <h5 className='mb-0 text-primary'>{props.content.properties.title}</h5>
            </Card.Header>
            <Card.Body>
                <p className='mb-0 ps-3'>{props.content.properties.description}</p>
            </Card.Body>
        </Card>
    );
};

export default BasicCard;
