const windowHeight = window.innerHeight;

export const handleNavbarTransparency = () => {
    const scrollTop = window.scrollY;
    let alpha = (scrollTop / windowHeight) * 2;
    alpha >= 1 && (alpha = 1);
    const documentClass = document.getElementsByClassName('navbar-theme')[0] as any;

    documentClass.style.backgroundColor = `rgba(11, 23, 39, ${alpha})`;
};
