import { OrganizationRequest } from '../../../types/api/request/organization';
import OrganizationInfo from '../../../types/shared/admin/organization_info';
import { WebRequest, WebResponse } from '../../../types/shared/web';
import { adminPaths, adminWebService } from '../web/http/admin';

export const createOrganization = async (orgInfo: OrganizationInfo): Promise<WebResponse<void>> => {
    const request: WebRequest = {
        path: adminPaths.organizations.post,
        body: orgInfo
    };

    return adminWebService.post<void>(request);
};

export const getCurrentOrganization = async (
    requestParams?: OrganizationRequest
): Promise<WebResponse<OrganizationInfo>> => {
    const path =
        requestParams?.organizationId.length > 0
            ? adminPaths.organizations.get.replace('{organizationId}', requestParams.organizationId)
            : adminPaths.organizations.current.get;

    const request: WebRequest = {
        path
    };

    return adminWebService.get<OrganizationInfo>(request);
};

export const getAllOrganizations = async (): Promise<WebResponse<Array<OrganizationInfo>>> => {
    const request: WebRequest = {
        path: adminPaths.organizations.getAll
    };

    return adminWebService.get<Array<OrganizationInfo>>(request);
};

export const associateStoreToOrganization = async (organizationId: string, token: string): Promise<WebResponse> => {
    const path = adminPaths.organizations.stores.post.replace('{organizationId}', organizationId);

    const request: WebRequest = {
        path,
        body: { token }
    };

    return adminWebService.post(request);
};
