import { Order } from '../../types/api/response/orders/order';
import { numberFormatter } from './utils';

export const isTrue = (value: string | null): boolean => {
    const truthy: string[] = ['true', 'True', '1'];

    return value != null && truthy.includes(value);
};

export const getSalesTotalFormat = (sales: Array<Order>) => {
    const total = sales.reduce((partialSum, order) => partialSum + order.total, 0);
    return `$${numberFormatter(total)}`;
};

export const getPercent = (price1: number, price2: number): number => {
    const min = Math.min(price1, price2);
    const max = Math.max(price1, price2);

    if (max == 0) {
        return min == 0 ? 0 : 100;
    }

    if (min == 0) {
        return max == 0 ? 0 : 100;
    }

    return 100 - (min / max) * 100;
};
