import { FC, useRef } from 'react';
import { Card, Col, Nav, Row } from 'react-bootstrap';
import useScrollSpy from 'react-use-scrollspy';
import BasicCard from '../../components/Common/BasicCard';
import PageHeader from '../../components/Common/PageHeader';
import Section from '../../components/Common/Section';
import content from '../../lib/data/content/about';
import { ComponentContent } from '../../types/content/component';

const About: FC = () => {
    const cards: Array<ComponentContent> = content.components.filter(c => c.component == BasicCard);

    const sectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null)
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: sectionRefs,
        offsetPx: -150
    });

    const pageHeader = content.components.findLast(c => c.component == PageHeader);

    return (
        <>
            <Section className='py-0 pt-5'>
                <PageHeader content={pageHeader} titleTag={'h2'} />
            </Section>

            <Section className='py-0 pt-3'>
                <Row className='g-0'>
                    <Col lg={8} className='pe-lg-2 order-1 order-lg-0'>
                        {cards &&
                            cards.map((c, i) => (
                                <BasicCard
                                    paragraphClass='mb-0 ps-3'
                                    content={c}
                                    id={i.toString()}
                                    ref={sectionRefs[i]}
                                    key={i}
                                />
                            ))}
                    </Col>
                    <Col lg={4} className='ps-lg-2 mb-3'>
                        <div className='sticky-sidebar'>
                            <Card className='sticky-top'>
                                <Card.Header className='border-bottom'>
                                    <h6 className='mb-0 fs-0'>On this page</h6>
                                </Card.Header>
                                <Card.Body>
                                    <div id='sidebar' className='terms-sidebar nav flex-column fs--1'>
                                        {cards &&
                                            cards.map((c, i) => (
                                                <Nav.Item>
                                                    <Nav.Link
                                                        href={`#${i}`}
                                                        className='nav-link px-0 py-1'
                                                        active={activeSection === i}
                                                    >
                                                        {c.properties.title}
                                                    </Nav.Link>
                                                </Nav.Item>
                                            ))}
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Section>
        </>
    );
};

export default About;
