import { Reducer, createReducer } from '@reduxjs/toolkit';
import { getOrders } from '../../../lib/services/management/orders';
import { Order } from '../../../types/api/response/orders/order';
import { RootState } from '../../root_state';
import { BaseFetchRedux } from '../fetch_redux';
import { initialBaseFetchState } from '../initial_state';
import { BASE_API_SUCCESS, BaseApiAction, BaseApiSuccessAction } from '../types';
import { OrdersState } from './state';

type RequestOrdersByDate = {
    fromDate: Date;
    toDate: Date;
};

class OrdersRedux extends BaseFetchRedux<OrdersState, BaseApiAction> {
    protected key = 'orders';
    protected get = getOrders;

    getCurrentState(rootState: RootState) {
        return rootState.ordersState;
    }

    getInitialState(): OrdersState {
        return {
            ...initialBaseFetchState,
            data: [],
            currentFromDate: undefined,
            currentToDate: undefined
        };
    }

    success(data: any, rest: any): BaseApiSuccessAction {
        return {
            type: BASE_API_SUCCESS,
            payload: {
                body: data.body,
                from: new Date(rest.fromDate),
                to: new Date(rest.toDate)
            }
        };
    }

    public createReducer(): Reducer<OrdersState, BaseApiAction> {
        return createReducer<OrdersState>(this.getInitialState, {
            BASE_API_FETCH: (state, action) => {
                state.hasErrors = false;
                state.isLoading = true;
                state.success = false;
                state.messages = [];
            },
            BASE_API_SUCCESS: (state, action) => {
                const { payload } = action;

                state.hasErrors = false;
                state.isLoading = false;
                state.success = true;
                state.messages = action?.payload?.messages;
                state.data = OrdersRedux.mergeOrders(state.data, payload?.body);

                const dates = OrdersRedux.setCurrentDateRange(
                    { from: state.currentFromDate, to: state.currentToDate },
                    { ...payload }
                );

                state.currentFromDate = dates.currentFromDate;
                state.currentToDate = dates.currentToDate;
            },
            BASE_API_ERROR: (state, action) => {
                state.hasErrors = true;
                state.isLoading = false;
                state.success = false;
                state.messages = action?.payload?.messages;
            }
        });
    }

    static mergeOrders = (source: Array<Order>, destination: Array<Order>): Array<Order> => {
        const mergedOrders: Array<Order> = [...destination];

        source.forEach((order: Order) => {
            const matchingOrderIndex = destination.findIndex((destOrder: Order) => destOrder.id === order.id);
            if (matchingOrderIndex !== -1) {
                mergedOrders[matchingOrderIndex] = { ...mergedOrders[matchingOrderIndex], ...order };
            } else {
                mergedOrders.push(order);
            }
        });

        return mergedOrders;
    };

    static setCurrentDateRange = (
        source: { from: Date; to: Date },
        destination: { from: Date; to: Date }
    ): { currentFromDate: Date; currentToDate: Date } => {
        let currentFromDate = source.from;
        let currentToDate = source.to;

        if (!destination) {
            return { currentFromDate, currentToDate };
        }

        if (source.from == undefined || destination.from < source.from) {
            currentFromDate = destination.from;
        }

        if (source.to == undefined || destination.to > source.to) {
            currentToDate = destination.to;
        }

        return {
            currentFromDate,
            currentToDate
        };
    };
}

const ordersRedux = new OrdersRedux();

export { ordersRedux };
export type { RequestOrdersByDate };
