import { Reducer } from 'redux';
import { fetchErrorState, fetchState } from '../../fetch/reducers';
import { getInitialSessionState } from '../initial_state';
import { LoginPhase } from '../login/phase';
import { SessionState } from '../state';
import {
    LOG_OUT_CLEARING_SESSION,
    LOG_OUT_FAILURE,
    LOG_OUT_FETCH,
    LOG_OUT_SESSION_CLEARED,
    LOG_OUT_SUCCESS,
    LogoutAction
} from './types';

export const logOutReducer: Reducer<SessionState, LogoutAction> = (
    state = getInitialSessionState(),
    action: LogoutAction
): SessionState => {
    const { type, payload } = action;
    switch (type) {
        case LOG_OUT_FETCH:
            return {
                ...fetchState(state, action),
                loginPhase: LoginPhase.LoggingOut
            };
        case LOG_OUT_SUCCESS:
            return {
                ...getInitialSessionState(),
                loginPhase: LoginPhase.LoggedOut
            };
        case LOG_OUT_FAILURE:
            return {
                ...fetchErrorState(state, action)
            };
        case LOG_OUT_CLEARING_SESSION:
            return {
                ...fetchErrorState(state, action)
            };
        case LOG_OUT_SESSION_CLEARED:
            return {
                ...fetchErrorState(state, action),
                sessionActive: false,
                sessionToken: null,
                loginToken: null
            };
        default:
            return state;
    }
};
