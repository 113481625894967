import { Reducer, createAction, createReducer } from '@reduxjs/toolkit';
import { getCurrentStore } from '../../../lib/services/management/store';
import { BaseAction } from '../../../types/react/redux/base_action';
import { Store } from '../../../types/shared/admin/stores/store';
import { RootState } from '../../root_state';
import { BaseFetchRedux } from '../fetch_redux';
import { initialBaseFetchState } from '../initial_state';
import { BASE_API_ERROR, BASE_API_FETCH, BASE_API_SUCCESS, BaseApiAction } from '../types';
import { StoreState } from './state';
import { UPDATE_UNASSOCIATED_STORE } from './types';

class StoreRedux extends BaseFetchRedux<StoreState, BaseApiAction> {
    protected key = 'Store';
    protected get = getCurrentStore;

    public createReducer(): Reducer<StoreState, BaseAction> {
        const fetchAction = createAction<void>(`${BASE_API_FETCH}_${this.key}`);
        const addUnassociatedStoreAction = createAction<string>(UPDATE_UNASSOCIATED_STORE);
        const successAction = createAction<{ body: Store; messages: [] }>(`${BASE_API_SUCCESS}_${this.key}`);
        const errorAction = createAction<{ messages: [] }>(`${BASE_API_ERROR}_${this.key}`);

        return createReducer<StoreState>(this.getInitialState, builder => {
            builder.addCase(fetchAction, (state, action) => {
                state.hasErrors = false;
                state.isLoading = true;
                state.success = false;
                state.messages = [];
            });

            builder.addCase(successAction, (state, action) => {
                const { payload } = action;

                state.hasErrors = false;
                state.isLoading = false;
                state.success = true;
                state.messages = payload?.messages;
                state.data = payload?.body;
            });

            builder.addCase(errorAction, (state, action) => {
                state.hasErrors = true;
                state.isLoading = false;
                state.success = false;
                state.messages = action?.payload?.messages;
            });

            builder.addCase(addUnassociatedStoreAction, (state, action) => {
                state.unassociatedStoreToken = action.payload;
            });
        });
    }

    updateUnassociatedStoreToken(token: string): BaseAction {
        return {
            type: UPDATE_UNASSOCIATED_STORE,
            payload: token
        };
    }

    getCurrentState(rootState: RootState): StoreState {
        return rootState.storeState;
    }

    getInitialState(): StoreState {
        return {
            ...initialBaseFetchState,
            data: null
        };
    }
}

const storeRedux = new StoreRedux();

export { storeRedux };
