import { BaseAction } from '../../../types/react/redux/base_action';

export const LOG_OUT_FETCH = 'LOG_OUT_FETCH';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
export const LOG_OUT_FAILURE = 'LOG_OUT_FAILURE';
export const LOG_OUT_CLEARING_SESSION = 'LOG_OUT_CLEARING_SESSION';
export const LOG_OUT_SESSION_CLEARED = 'LOG_OUT_SESSION_CLEARED';

export interface LogoutFetchAction extends BaseAction<typeof LOG_OUT_FETCH> {}

export interface LogoutSuccessAction extends BaseAction<typeof LOG_OUT_SUCCESS> {}

export interface LogoutFailureAction extends BaseAction<typeof LOG_OUT_FAILURE> {}

export interface LogoutClearingSessionAction extends BaseAction<typeof LOG_OUT_CLEARING_SESSION> {}

export interface LogoutSessionClearedAction extends BaseAction<typeof LOG_OUT_SESSION_CLEARED> {}

export type LogoutAction =
    | LogoutFetchAction
    | LogoutSuccessAction
    | LogoutFailureAction
    | LogoutClearingSessionAction
    | LogoutSessionClearedAction;
