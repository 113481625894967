import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { cssTransition } from 'react-toastify';

export const Fade = cssTransition({ enter: 'fadeIn', exit: 'fadeOut' });

export const CloseButton = (props: { closeToast: any }) => (
    <FontAwesomeIcon icon='times' className='my-2 fs--2' style={{ opacity: 0.5 }} onClick={props.closeToast} />
);

CloseButton.propTypes = { closeToast: PropTypes.func };
