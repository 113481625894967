import { TopSellingApiRequest } from '../../../types/api/request/top_selling/top_selling_request';
import { TopSellingProducts } from '../../../types/api/response/top_selling/products';
import { WebRequest, WebResponse } from '../../../types/shared/web';
import { apiPaths, apiWebService } from '../web/http/api';

export const getTopSellingProducts = async (
    requestQuery?: TopSellingApiRequest
): Promise<WebResponse<TopSellingProducts>> => {
    const request: WebRequest = {
        path: apiPaths.topSellingProducts.get,
        query: requestQuery
    };

    return apiWebService.get<TopSellingProducts>(request);
};
