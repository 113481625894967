import is from 'is_js';
import { useEffect } from 'react';
import { NavbarPosition } from '../../redux/page/state';

export const useGlobalStyleEffect = (options: {
    isFluid: boolean;
    isRTL: boolean;
    isDark: boolean;
    navbarPosition: NavbarPosition;
    isNavbarVerticalCollapsed: boolean;
}) => {
    const HTMLClassList = document.getElementsByTagName('html')[0].classList;

    useEffect(() => {
        if (options.isNavbarVerticalCollapsed) {
            HTMLClassList.add('navbar-vertical-collapsed');
        } else {
            HTMLClassList.remove('navbar-vertical-collapsed');
        }
        return () => {
            HTMLClassList.remove('navbar-vertical-collapsed-hover');
        };
    }, [options.isNavbarVerticalCollapsed, HTMLClassList]);

    useEffect(() => {
        if (is.windows()) {
            HTMLClassList.add('windows');
        }
        if (is.chrome()) {
            HTMLClassList.add('chrome');
        }
        if (is.firefox()) {
            HTMLClassList.add('firefox');
        }
        if (is.safari()) {
            HTMLClassList.add('safari');
        }
    }, [HTMLClassList]);

    useEffect(() => {
        if (options.navbarPosition === 'double-top') {
            HTMLClassList.add('double-top-nav-layout');
        }
        return () => HTMLClassList.remove('double-top-nav-layout');
    }, [options.navbarPosition]);

    useEffect(() => {
        document.documentElement.classList[options.isDark ? 'add' : 'remove']('dark');
    }, [options.isDark]);
};
