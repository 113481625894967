import { User } from 'firebase/auth';
import { Reducer } from 'redux';
import { fetchErrorState, fetchState, fetchSuccessState } from '../../fetch/reducers';
import { getInitialSessionState } from '../initial_state';
import { SessionState } from '../state';
import { LoginPhase } from './phase';
import {
    LOG_IN_FAILURE,
    LOG_IN_FETCH,
    LOG_IN_GATHERING_TOKENS,
    LOG_IN_INITIALIZING_SESSION,
    LOG_IN_SESSION_INITIALIZED,
    LOG_IN_SOURCE_SWITCH,
    LOG_IN_SUCCESS,
    LOG_IN_TOKENS_ACQUIRED,
    LoginAction,
    POST_LOG_IN
} from './types';

export const logInReducer: Reducer<SessionState, LoginAction> = (
    state = getInitialSessionState(),
    action: LoginAction
): SessionState => {
    const { type, payload } = action;
    switch (type) {
        case LOG_IN_SOURCE_SWITCH:
            return {
                ...state,
                loginSource: payload.sourceType
            };
        case LOG_IN_SUCCESS:
            const user = payload.user as User;

            return {
                ...fetchSuccessState(state, action),
                email: user.email ?? '',
                userId: user.uid,
                loginPhase: LoginPhase.LoggedIn
            };
        case LOG_IN_FETCH:
            return {
                ...fetchState(state, action),
                loginPhase: LoginPhase.LoggingIn
            };
        case LOG_IN_FAILURE:
            return {
                ...fetchErrorState(state, action),
                loginPhase: LoginPhase.LoginError
            };
        case LOG_IN_GATHERING_TOKENS:
            return {
                ...fetchState(state, action),
                loginPhase: LoginPhase.GatheringTokens
            };
        case LOG_IN_TOKENS_ACQUIRED:
            return {
                ...fetchSuccessState(state, action),
                loginToken: payload.loginToken,
                loginPhase: LoginPhase.TokensGathered
            };
        case LOG_IN_INITIALIZING_SESSION:
            return {
                ...fetchState(state, action),
                loginPhase: LoginPhase.InitializingSession
            };
        case LOG_IN_SESSION_INITIALIZED:
            return {
                ...fetchSuccessState(state, action),
                sessionActive: true,
                loginPhase: LoginPhase.SessionInitialized
            };
        case POST_LOG_IN:
            return {
                ...state,
                loginPhase: LoginPhase.PostLogin
            };
    }

    return state;
};
