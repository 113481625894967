import { BaseAction } from '../../types/react/redux/base_action';
import { BaseFetchState } from './base_fetch_state';

export const fetchErrorState = <TFetchState extends BaseFetchState>(
    state: TFetchState,
    action: BaseAction
): TFetchState => ({
    ...state,
    hasErrors: true,
    isLoading: false,
    success: false,
    messages: action?.payload?.messages
});

export const fetchSuccessState = <TFetchState extends BaseFetchState>(
    state: TFetchState,
    action: BaseAction
): TFetchState => ({
    ...state,
    hasErrors: false,
    isLoading: false,
    success: true,
    messages: action?.payload?.messages
});

export const fetchState = <TFetchState extends BaseFetchState>(
    state: TFetchState,
    action: BaseAction
): TFetchState => ({
    ...state,
    hasErrors: false,
    isLoading: true,
    success: false,
    messages: []
});
