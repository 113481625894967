import { AccountConstants } from '../../lib/constants/account';
import { getTypeValueFromStorage } from '../../lib/helpers/storage_helpers';
import { LoginSource } from '../../types/login/source';
import { LoginPhase } from './login/phase';
import { SessionState } from './state';

export const getInitialSessionState = (): SessionState => {
    const initialSessionState: SessionState = {
        sessionActive: false,
        loginSource: getTypeValueFromStorage(AccountConstants.LOGIN_SOURCE, LoginSource.Default),
        success: false,
        messages: [],
        isLoading: false,
        hasErrors: false,
        sessionExpiration: undefined,
        email: '',
        userId: '',
        loginPhase: LoginPhase.Uninitialized,
        currentStoreId: ''
    };

    return initialSessionState;
};
