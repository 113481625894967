import { FC } from 'react';
import { Link } from 'react-router-dom';

export interface FooterListProps {
    list: Array<any>;
}

const FooterList: FC<FooterListProps> = ({ list }) => (
    <ul className='list-unstyled'>
        {list.map(({ title, to }, index) => (
            <li className='mb-1' key={index}>
                <Link className='text-600' to={to}>
                    {title}
                </Link>
            </li>
        ))}
    </ul>
);

export default FooterList;
