import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import bgImg from '../../assets/img/generic/Banner.png';
import { PageSettingsState } from '../../redux/page/state';
import { RootState } from '../../redux/root_state';
import Section from '../Common/Section';

interface HeroProps {
    pageState: PageSettingsState;
}

const Hero: FC<HeroProps> = (props: HeroProps) => {
    const navigate = useNavigate();

    return (
        <Section className='py-0 overflow-hidden light' image={bgImg} position='center bottom' overlay>
            <Row className='justify-content-center align-items-center pt-8 pt-lg-10 pb-lg-9 pb-xl-0'>
                <Col md={11} lg={8} xl={4} className='pb-7 pb-xl-9 text-center text-xl-start'>
                    <Button
                        onClick={() => navigate('/')}
                        variant='outline-light'
                        size='lg'
                        className='border-2 rounded-pill mt-4 fs-0 py-2'
                    >
                        Play!
                        <FontAwesomeIcon icon='play' transform='shrink-6 down-1 right-5' />
                    </Button>
                </Col>
            </Row>
        </Section>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        pageState: state.pageSettingsState
    };
};

export default connect(mapStateToProps)(Hero);
