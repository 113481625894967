import { Dispatch } from 'redux';
import { BaseAction } from '../../types/react/redux/base_action';
import { RootState } from '../root_state';

export const BASE_API_FETCH = 'BASE_API_FETCH';
export const BASE_API_ERROR = 'BASE_API_ERROR';
export const BASE_API_SUCCESS = 'BASE_API_SUCCESS';

export interface BaseApiFetchAction extends BaseAction<typeof BASE_API_FETCH> {}
export interface BaseApiErrorAction extends BaseAction<typeof BASE_API_ERROR> {}
export interface BaseApiSuccessAction extends BaseAction<typeof BASE_API_SUCCESS> {}

export type BaseApiAction = BaseApiFetchAction | BaseApiErrorAction | BaseApiSuccessAction;

export type ThunkRedux = {
    dispatch: Dispatch<any>;
    state: RootState;
    extra?: unknown;
    rejectValue?: unknown;
    serializedErrorType?: unknown;
    pendingMeta?: unknown;
    fulfilledMeta?: unknown;
    rejectedMeta?: unknown;
};
