import { getInitialSessionState } from './initial_state';
import { logInReducer } from './login/reducers';
import { logOutReducer } from './logout/reducers';
import { registerReducer } from './register/reducers';
import { SessionState } from './state';

export const accountReducers = (state = getInitialSessionState(), action: any): SessionState => {
    state = logInReducer(state, action);
    state = logOutReducer(state, action);
    state = registerReducer(state, action);

    return state;
};
