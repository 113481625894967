import { FC } from 'react';
import { connect } from 'react-redux';
import Hero from '../../components/Landing/Hero';
import StandardHorizontalNavbar from '../../components/Landing/Navigation/StandardHorizontalNavbar';
import loggedInLinks from '../../lib/data/routes/logged_in_links';
import loggedOutLinks from '../../lib/data/routes/logged_out_links';
import { SessionState } from '../../redux/account/state';
import { RootState } from '../../redux/root_state';

interface LandingProps {
    sessionState: SessionState;
}

const Landing: FC<LandingProps> = (props: LandingProps) => {
    const routes = props.sessionState.sessionActive ? loggedInLinks : loggedOutLinks;

    return (
        <>
            <StandardHorizontalNavbar routes={routes} />
            <Hero />
            {/* <Partners />
            <ProcessesList />
            <Services />
            <Testimonial />
            <Cta /> */}
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        sessionState: state.sessionState
    };
};

export default connect(mapStateToProps)(Landing);
