import { FC } from 'react';
import { connect } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import About from '../components/Content/About';
import Landing from '../components/Content/Landing';
import Privacy from '../components/Content/Privacy';
import Error404 from '../components/Errors/Error404';
import Error500 from '../components/Errors/Error500';
import ErrorLayout from '../layouts/ErrorLayout';
import MainLayout from '../layouts/Main.Layout';
import { SessionState } from '../redux/account/state';
import { RootState } from '../redux/root_state';

interface RoutesProps {
    sessionState: SessionState;
}

const DefaultRoutes: FC<RoutesProps> = (props: RoutesProps) => {
    return (
        <Routes>
            <Route element={<MainLayout />}>
                <Route path='/' element={<Landing />} />
                <Route path='/privacy' element={<Privacy />} />
                <Route path='/about' element={<About />} />
            </Route>

            <Route element={<ErrorLayout />}>
                <Route path='errors/404' element={<Error404 />} />
                <Route path='errors/500' element={<Error500 />} />
            </Route>

            {/* <Navigate to="/errors/404" /> */}
            <Route path='*' element={<Navigate to='/errors/404' replace />} />
        </Routes>
    );
};

const mapStateToProps = (rootState: RootState) => {
    return {
        sessionState: rootState.sessionState
    };
};

export default connect(mapStateToProps)(DefaultRoutes);
