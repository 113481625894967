import BasicCard from '../../../components/Common/BasicCard';
import PageHeader from '../../../components/Common/PageHeader';
import { PageContent } from '../../../types/content/page';

const content: PageContent = {
    path: '/privacy',
    components: [
        {
            component: PageHeader,
            properties: {
                image: '',
                preTitle: '',
                title: 'Privacy',
                description:
                    'Welcome to Freedom Fighters 2! These terms and conditions outline the rules and regulations for the use of Freedom Fighters 2, owned and operated by Tidal Wave Media LLC. By accessing and using this game, you accept and agree to be bound by these Terms and Conditions. If you do not agree with any part of the terms, you must not access the game.'
            }
        },
        {
            component: BasicCard,
            properties: {
                title: 'License',
                description:
                    'Tidal Wave Media LLC grants you a revocable, non-exclusive, non-transferable, limited license to download, install, and use the game strictly in accordance with these Terms.'
            }
        },
        {
            component: BasicCard,
            properties: {
                title: 'User Accounts',
                description:
                    'When you create an account with us, you guarantee that you are above the age of 18, and that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account.'
            }
        },
        {
            component: BasicCard,
            properties: {
                title: 'Intellectual Property',
                description:
                    'The game and its original content, features, and functionality are and will remain the exclusive property of Tidal Wave Media LLC and its licensors. The game is protected by copyright, trademark, and other laws of both the United States and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Tidal Wave Media LLC.'
            }
        },
        {
            component: BasicCard,
            properties: {
                title: 'Restrictions',
                description:
                    'You agree not to, and you will not permit others to: License, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose, or otherwise commercially exploit the game. Modify, make derivative works of, disassemble, decrypt, reverse compile, or reverse engineer any part of the game.'
            }
        },
        {
            component: BasicCard,
            properties: {
                title: 'Termination',
                description:
                    'We may terminate or suspend your account and bar access to the game immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.'
            }
        },
        {
            component: BasicCard,
            properties: {
                title: 'Limitation of Liability',
                description:
                    'In no event shall Tidal Wave Media LLC, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from Your access to or use of or inability to access or use the game. Any conduct or content of any third party on the game. Any content obtained from the game. Unauthorized access, use, or alteration of your transmissions or content.'
            }
        },
        {
            component: BasicCard,
            properties: {
                title: 'Governing Law',
                description:
                    'These Terms shall be governed and construed in accordance with the laws of Your State/Country, without regard to its conflict of law provisions.'
            }
        },
        {
            component: BasicCard,
            properties: {
                title: 'Changes to These Terms',
                description:
                    'We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.'
            }
        },
        {
            component: BasicCard,
            properties: {
                title: 'Contact Us',
                description:
                    'If you have any questions about these Terms, please contact us at: Email: contact@tidalwavemedia.net'
            }
        }
    ]
};

export default content;
