import { getCurrentOrganization } from '../../../lib/services/management/organization';
import { RootState } from '../../root_state';
import { BaseFetchRedux } from '../fetch_redux';
import { initialBaseFetchState } from '../initial_state';
import { BaseApiFetchAction } from '../types';
import { OrganizationState } from './state';

class OrganizationRedux extends BaseFetchRedux<OrganizationState, BaseApiFetchAction> {
    protected key = 'organization';
    protected get = getCurrentOrganization;

    getInitialState(): OrganizationState {
        const initialOrganizationState: OrganizationState = {
            ...initialBaseFetchState,
            data: {}
        };

        return initialOrganizationState;
    }

    getCurrentState(rootState: RootState): OrganizationState {
        return rootState.organizationState;
    }
}

const organizationRedux = new OrganizationRedux();

export { organizationRedux };
