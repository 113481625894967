import { FC } from 'react';
import { Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AnyAction, Dispatch } from 'redux';
import { flatRoutes } from '../../../lib/helpers/utils';
import { setNavbarCollapse, showBurgerMenu } from '../../../redux/page/actions';
import { PageSettingsState } from '../../../redux/page/state';
import { RootState } from '../../../redux/root_state';
import { PageRoutesType } from '../../../types/react';
import NavbarDropdown from './NavbarDropdown';

interface NavbarTopDropDownMenusProps {
    routes: Array<PageRoutesType>;
    pageState: PageSettingsState;
    showBurgerMenu: (show: boolean) => void;
    setNavbarCollapse: (collapse: boolean) => void;
}

const NavbarTopDropDownMenus: FC<NavbarTopDropDownMenusProps> = (props: NavbarTopDropDownMenusProps) => {
    return (
        <>
            {props.routes.map((routeContainer, rcIndex) => {
                if (routeContainer.children) {
                    const asFlat = flatRoutes(routeContainer.children);
                    return (
                        <NavbarDropdown title={routeContainer.name} key={rcIndex}>
                            {asFlat.map((route, index) => {
                                return (
                                    <Dropdown.Item
                                        key={index}
                                        as={Link}
                                        className={route.active ? 'link-600' : 'text-500'}
                                        to={route.to}
                                    >
                                        {route.name}
                                    </Dropdown.Item>
                                );
                            })}
                        </NavbarDropdown>
                    );
                } else {
                    return (
                        <Dropdown.Item
                            className='m-auto mx-2'
                            key={routeContainer.name}
                            as={Link}
                            to={routeContainer.to}
                        >
                            {routeContainer.name}
                        </Dropdown.Item>
                    );
                }
            })}
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        pageState: state.pageSettingsState
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return {
        showBurgerMenu: (show: boolean) => dispatch(showBurgerMenu(show)),
        setNavbarCollapse: (collapse: boolean) => dispatch(setNavbarCollapse(collapse))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavbarTopDropDownMenus);
