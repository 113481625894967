import { Reducer } from 'redux';
import { fetchErrorState, fetchState, fetchSuccessState } from '../../fetch/reducers';
import { getInitialSessionState } from '../initial_state';
import { LoginPhase } from '../login/phase';
import { SessionState } from '../state';
import { REGISTER_FAILURE, REGISTER_FETCH, REGISTER_SUCCESS, RegisterAction } from './types';

export const registerReducer: Reducer<SessionState, RegisterAction> = (
    state = getInitialSessionState(),
    action: RegisterAction
): SessionState => {
    const { type, payload } = action;
    switch (type) {
        case REGISTER_FETCH:
            return {
                ...fetchState(state, action),
                loginPhase: LoginPhase.Registering
            };
        case REGISTER_FAILURE:
            return {
                ...fetchErrorState(state, action),
                feedback: payload.feedback
            };
        case REGISTER_SUCCESS:
            return {
                ...fetchSuccessState(state, action),
                loginPhase: LoginPhase.Registered
            };
    }

    return state;
};
