import { Order } from '../../types/api/response/orders/order';
import { TimeSpan } from '../../types/react/time_span';

export type DateRanges = {
    fromDate: Date;
    toDate: Date;
    previousDate: Date;
};

export type DateRangesAsString = {
    fromDate: string;
    toDate: string;
    previousDate: string;
};

/**
 * Function to get date ranges based on the specified time span.
 * @param timeSpan The time span to calculate date ranges for (Daily, Monthly, Weekly, Yearly).
 * @returns An object containing the fromDate, toDate, and previousDate.
 */
export const getDateRangesFromTimespan = (timeSpan: TimeSpan): DateRanges => {
    // Get the current date
    let toDate = new Date();

    let fromDate = new Date(toDate);
    let previousDate = new Date(toDate);

    switch (timeSpan) {
        case TimeSpan.Daily:
            // Calculate the from and previous dates for daily time span
            previousDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate() - 1);
            break;
        case TimeSpan.Weekly:
            // Calculate the from and previous dates for weekly time span
            toDate = new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate() - 1);
            fromDate = new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate() - 6);
            previousDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate() - 7);
            break;
        case TimeSpan.Monthly:
            // Calculate the from and previous dates for monthly time span
            fromDate = new Date(toDate.getFullYear(), toDate.getMonth() - 1, toDate.getDate());
            previousDate = new Date(fromDate.getFullYear(), fromDate.getMonth() - 1, fromDate.getDate());
            break;
        case TimeSpan.Yearly:
            // Calculate the from and previous dates for yearly time span
            fromDate = new Date(toDate.getFullYear() - 1, toDate.getMonth(), toDate.getDate());
            previousDate = new Date(fromDate.getFullYear() - 1, fromDate.getMonth(), fromDate.getDate());
            break;
        case TimeSpan.AllTime:
            // Calculate the from and previous dates for yearly time span
            fromDate = new Date(1970, 0, 1, 0, 0, 0, 0); // just do linux epoch
            previousDate = fromDate;
            break;
    }

    previousDate.setHours(0, 0, 0, 0);
    fromDate.setHours(0, 0, 0, 0);
    toDate.setHours(23, 59, 59, 999);

    // Return the date ranges
    return {
        fromDate,
        toDate,
        previousDate
    };
};

export const getDateRangesAsStringFromTimespan = (timeSpan: TimeSpan): DateRangesAsString => {
    const dateRanges = getDateRangesFromTimespan(timeSpan);

    return {
        fromDate: dateRanges.fromDate.toUTCString(),
        previousDate: dateRanges.previousDate.toUTCString(),
        toDate: dateRanges.toDate.toUTCString()
    };
};

export const isOrderInDateRange = (order: Order, fromDate: Date, toDate: Date): boolean => {
    const orderDate = new Date(order.dateCreated);
    const compareFrom = compareDates(orderDate, fromDate);
    const compareTo = compareDates(orderDate, toDate);
    return compareFrom >= 0 && compareTo <= 0;
};

/**
 * Function to compare two dates and return a number based on their relationship.
 * If -1 is returned, it means date1 is less than date2.
 * If 0 is returned, it means date1 is equal to date2.
 * If 1 is returned, it means date1 is greater than date2.
 *
 * @param date1 The first date to compare.
 * @param date2 The second date to compare.
 * @returns A number indicating the relationship between the two dates.
 */
export const compareDates = (date1: Date, date2: Date): number => {
    // Create new date objects with only year, month, and day values
    const compare1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const compare2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());

    // If compare1 is smaller than compare2, return -1
    if (compare1 < compare2) {
        return -1;
    }

    // If compare1 is greater than compare2, return 1
    if (compare1 > compare2) {
        return 1;
    }

    // Otherwise, assume equal
    return 0;
};

export const getDaysOfWeek = (): Array<string> => {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const today = new Date();
    const dayOfWeek = today.getDay();

    // Move back to the first day of the week (Sunday) from today
    const startDate = new Date(today);
    startDate.setDate(today.getDate() - dayOfWeek);

    // Generate the array of dates from 6 days ago to today
    const daysArray: string[] = [];
    for (let i = 0; i < 7; i++) {
        const currentDate = new Date(startDate);
        currentDate.setDate(startDate.getDate() + i);
        daysArray.push(daysOfWeek[currentDate.getDay()]);
    }

    return daysArray;
};

type Greeting = 'Morning' | 'Afternoon' | 'Evening';
export const getDayGreeting = (): Greeting => {
    const today = new Date();
    const curHr = today.getHours();

    let greeting: Greeting = 'Evening';

    if (curHr < 12) {
        greeting = 'Morning';
    } else if (curHr < 18) {
        greeting = 'Afternoon';
    }

    return greeting;
};
