import { PageConstants } from '../../lib/constants/page';
import { PAGE_SETTINGS_UPDATE, PageSettingsUpdateAction } from './types';

export const setFluid = (isFluid: boolean): PageSettingsUpdateAction => {
    localStorage.setItem(PageConstants.IS_FLUID, isFluid.toString());

    return {
        type: PAGE_SETTINGS_UPDATE,
        payload: {
            isFluid
        }
    };
};

export const setRTL = (isRTL: boolean): PageSettingsUpdateAction => {
    localStorage.setItem(PageConstants.IS_RTL, isRTL.toString());

    return {
        type: PAGE_SETTINGS_UPDATE,
        payload: {
            isRTL
        }
    };
};

export const setDarkMode = (isDark: boolean): PageSettingsUpdateAction => {
    localStorage.setItem(PageConstants.IS_DARK, isDark.toString());

    return {
        type: PAGE_SETTINGS_UPDATE,
        payload: {
            isDark
        }
    };
};

export const setNavbarPosition = (
    navbarPosition: 'top' | 'vertical' | 'combo' | 'double-top'
): PageSettingsUpdateAction => {
    localStorage.setItem(PageConstants.NAVBAR_POSITION, navbarPosition);

    return {
        type: PAGE_SETTINGS_UPDATE,
        payload: {
            navbarPosition
        }
    };
};

export const setNavbarStyle = (
    navbarStyle: 'transparent' | 'vibrant' | 'inverted' | 'card'
): PageSettingsUpdateAction => {
    localStorage.setItem(PageConstants.NAVBAR_STYLE, navbarStyle);

    return {
        type: PAGE_SETTINGS_UPDATE,
        payload: {
            navbarStyle
        }
    };
};

export const setNavbarCollapse = (isNavbarCollapsed: boolean): PageSettingsUpdateAction => {
    return {
        type: PAGE_SETTINGS_UPDATE,
        payload: {
            isNavbarCollapsed
        }
    };
};

export const setNavbarVerticalCollapse = (isNavbarVerticalCollapsed: boolean): PageSettingsUpdateAction => {
    localStorage.setItem(PageConstants.IS_NAVBAR_VERTICAL_COLLAPSED, isNavbarVerticalCollapsed.toString());

    return {
        type: PAGE_SETTINGS_UPDATE,
        payload: {
            isNavbarVerticalCollapsed
        }
    };
};

export const showBurgerMenu = (showBurgerMenu: boolean): PageSettingsUpdateAction => {
    return {
        type: PAGE_SETTINGS_UPDATE,
        payload: {
            showBurgerMenu
        }
    };
};

export const isDashboardLoading = (loading: boolean): PageSettingsUpdateAction => {
    return {
        type: PAGE_SETTINGS_UPDATE,
        payload: {
            isDashboardLoading: loading
        }
    };
};
