export const setCookie = (name: string, value: string, expires?: Date | number, options?: any) => {
    if (typeof expires === 'number') {
        const expiresInMinutes = expires;
        const expirationDate = new Date();
        expirationDate.setTime(expirationDate.getTime() + expiresInMinutes * 60 * 1000);
        expires = expirationDate;
    } else if (!expires) {
        const expiresInDays = 1;
        expires = new Date();
        expires.setTime(expires.getTime() + expiresInDays * 24 * 60 * 60 * 1000);
    }

    const cookieOptions = {
        expires: expires.toUTCString(),
        path: '/',
        secure: true,
        ...options
    };

    document.cookie = `${name}=${encodeURIComponent(value)};${Object.entries(cookieOptions)
        .map(([key, val]) => `${key}=${val}`)
        .join(';')}`;
};

export const deleteCookie = (name: string) => {
    document.cookie = name + '=; Max-Age=-99999999;';
};

export const getCookieValue = (name: string): string => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(name + '=')) {
            return decodeURIComponent(cookie.substring(name.length + 1));
        }
    }

    return '';
};
