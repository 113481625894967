import classNames from 'classnames';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { HTMLElementType } from '../../types/react';

export interface LogoProps extends HTMLElementType {
    at?: 'navbar-vertical' | 'navbar-top' | 'auth';
    width?: number;
    textClass?: string;
}

const Logo: FC<LogoProps> = function Logo({ at = 'auth', width = 58, className, textClass, ...rest }) {
    return (
        <Link
            to='/'
            className={classNames(
                'text-decoration-none',
                { 'navbar-brand text-left': at === 'navbar-vertical' },
                { 'navbar-brand text-left': at === 'navbar-top' }
            )}
            {...rest}
        >
            <div
                className={classNames(
                    'd-flex',
                    {
                        'align-items-center py-3': at === 'navbar-vertical',
                        'align-items-center': at === 'navbar-top',
                        'flex-center fw-bolder fs-5 mb-4': at === 'auth'
                    },
                    className
                )}
            >
                {/* <img className='me-2' src={logo} alt='Logo' width={width} /> */}
                <span className={classNames('font-sans-serif', textClass)} style={{ fontSize: '1.5rem' }}>
                    Sales Sense
                </span>
            </div>
        </Link>
    );
};

export default Logo;
