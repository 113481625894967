import { getInitialSessionState } from './account/initial_state';
import { ordersRedux } from './fetch/orders';
import { organizationRedux } from './fetch/organization';
import { storeRedux } from './fetch/store';
import { topSellingProductsRedux } from './fetch/top_selling/products';
import { getInitialPageSettingsState } from './page/initial_state';
import { RootState } from './root_state';

export const initialRootState: RootState = {
    pageSettingsState: getInitialPageSettingsState(),
    sessionState: getInitialSessionState(),
    organizationState: organizationRedux.getInitialState(),
    ordersState: ordersRedux.getInitialState(),
    topSellingProductsState: topSellingProductsRedux.getInitialState(),
    storeState: storeRedux.getInitialState()
};
