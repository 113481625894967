export class WebRequestError extends Error {
    public statusCode: number;

    constructor(uri: string, responseBody: string, statusCode: number, message?: string) {
        super(message);

        this.statusCode = statusCode;
    }

    static fromWebError(error: any, uri: string) {
        return new WebRequestError(uri, '', error.statusCode ?? 500, error.message);
    }
}
