import { StoreRequest } from '../../../types/api/request/organization/stores';
import { Store } from '../../../types/shared/admin/stores/store';
import { WebRequest, WebResponse } from '../../../types/shared/web';
import { adminPaths, adminWebService } from '../web/http/admin';

export const getCurrentStore = async (requestParams?: StoreRequest): Promise<WebResponse<Store>> => {
    if (requestParams?.organizationId.length <= 0) {
        throw new Error('Request params not set for getCurrentStore');
    }

    let storePath;
    if (requestParams?.storeId?.length > 0) {
        storePath = adminPaths.organizations.stores.get
            .replace('{organizationId}', requestParams.organizationId)
            .replace('{storeId}', requestParams.storeId);
    } else {
        storePath = adminPaths.organizations.stores.current.get.replace(
            '{organizationId}',
            requestParams.organizationId
        );
    }

    const request: WebRequest = {
        path: storePath
    };

    return adminWebService.get<Store>(request);
};
