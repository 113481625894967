import { AccountConstants } from '../../lib/constants/account';
import { PageConstants } from '../../lib/constants/page';
import { getDayGreeting } from '../../lib/helpers/date_helpers';
import { getValueFromStorage } from '../../lib/helpers/storage_helpers';
import { isTrue } from '../../lib/helpers/value_helpers';
import { LoginSource } from '../../types/login/source';
import { NavbarPosition, NavbarStyle, PageSettingsState } from './state';

export const getInitialPageSettingsState = (): PageSettingsState => {
    const greeting = getDayGreeting();

    const initialState: PageSettingsState = {
        isFluid: true,
        isRTL: false,
        isDark: greeting == 'Evening',
        navbarPosition: 'vertical',
        showBurgerMenu: true,
        currency: '',
        isNavbarVerticalCollapsed: false,
        navbarStyle: 'card',
        showSettingPanel: false,
        isNavbarCollapsed: false,
        isLoaded: true,
        isDashboardLoading: false
    };

    const loginSource = getValueFromStorage(AccountConstants.LOGIN_SOURCE, LoginSource.Default.toString());

    initialState.isFluid = isTrue(getValueFromStorage(PageConstants.IS_FLUID, initialState.isFluid.toString()));
    initialState.isRTL = isTrue(getValueFromStorage(PageConstants.IS_RTL, initialState.isRTL.toString()));
    initialState.isDark = isTrue(getValueFromStorage(PageConstants.IS_DARK, initialState.isDark.toString()));
    initialState.navbarPosition = getValueFromStorage(
        PageConstants.NAVBAR_POSITION,
        initialState.navbarPosition
    ) as NavbarPosition;
    initialState.navbarStyle = getValueFromStorage(PageConstants.NAVBAR_STYLE, initialState.navbarStyle) as NavbarStyle;
    initialState.isNavbarVerticalCollapsed = isTrue(
        getValueFromStorage(
            PageConstants.IS_NAVBAR_VERTICAL_COLLAPSED,
            initialState.isNavbarVerticalCollapsed.toString()
        )
    );

    if (loginSource === LoginSource.Client.toString()) {
        initialState.isFluid = true;
        initialState.navbarPosition = 'vertical';
        initialState.navbarStyle = 'card';
    }

    return initialState;
};
