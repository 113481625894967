import { WebRequest, WebResponse } from '../../../../types/shared/web';

export const fakeFetch = (input: RequestInfo | URL, init?: RequestInit): Promise<Response> => {
    const basePath = '/fake_fetch';
    const regex = /\{([^}]+)\}/g;

    const urlInput = typeof input === 'string' ? new URL(input) : (input as URL);

    let path = urlInput.pathname;
    path = path.replace(regex, (_, word) => `[${word}]`);
    path = `${basePath}${path}.json`;

    return fetch(path);
};

export const handleFakeReturn = async <TRequest extends WebRequest, TBody extends any = {}>(
    url: string,
    request: TRequest,
    response: Response
): Promise<WebResponse<TBody>> => {
    const webRespnse: WebResponse<TBody> = {
        success: false,
        status_code: 0,
        messages: []
    };

    if (!response.ok) {
        webRespnse.messages.push('Fake object does not exist');
        return webRespnse;
    }

    if (response.body == null) {
        return webRespnse;
    }

    webRespnse.body = await response.json();
    webRespnse.body = evaluateExpressions(webRespnse.body);

    return webRespnse;
};

export const handleFakeError = (error: any, url: string) => {
    console.log(error);
};

const evaluateExpressions = (obj: any): any => {
    if (typeof obj === 'string' && obj.includes('{{') && obj.includes('}}')) {
        const regex = /\{\{(.*?)\}\}/g;
        return obj.replace(regex, (_, expression) => {
            try {
                // eslint-disable-next-line no-eval
                return eval(expression);
            } catch (err) {
                // Handle errors gracefully (you may want to log or handle this differently)
                return `Error evaluating expression: ${expression}`;
            }
        });
    }

    if (Array.isArray(obj)) {
        return obj.map(item => evaluateExpressions(item));
    }

    if (typeof obj === 'object' && obj !== null) {
        const newObj: any = {};
        for (const key in obj) {
            newObj[key] = evaluateExpressions(obj[key]);
        }
        return newObj;
    }

    return obj;
};
