import { configureStore } from '@reduxjs/toolkit';
import { Store } from 'redux';
import thunk from 'redux-thunk';
import { initialRootState } from './initial_state';
import rootReducerWithLoader from './reducers';
import { RootState } from './root_state';
import { AppAction } from './types';

const storeConfigured: Store<RootState, AppAction> = configureStore({
    reducer: rootReducerWithLoader,
    preloadedState: initialRootState,
    middleware: [thunk]
});

export default storeConfigured;
