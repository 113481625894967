export const bgWhiteIcons = [
    {
        icon: ['fab', 'facebook-f'],
        color: 'facebook',
        bg: 'white',
        href: 'https://www.facebook.com/gaming/freedomfighterstwo'
    }
];
export default [
    { icon: ['fab', 'facebook-f'], color: 'facebook', href: 'https://www.facebook.com/gaming/freedomfighterstwo' }
];
