import { getInitialPageSettingsState } from './initial_state';
import { PageSettingsState } from './state';
import { PAGE_SETTINGS_UPDATE, PageSettingsAction } from './types';

export const pageSettingsReducer = (
    state = getInitialPageSettingsState(),
    action: PageSettingsAction
): PageSettingsState => {
    const { type, payload } = action;

    switch (type) {
        case PAGE_SETTINGS_UPDATE:
            return {
                ...state,
                ...payload
            };
        default:
            return {
                ...state
            };
    }
};
