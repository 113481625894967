import { FirebaseApp, getApps, initializeApp } from 'firebase/app';
import { Auth, getAuth } from 'firebase/auth';

const appName = 'client';
const { REACT_APP_FIREBASE_API_KEY: apiKey, REACT_APP_FIREBASE_DOMAIN: authDomain } = process.env;

export const getFirebaseAuth = (): Auth => {
    return getAuth(getClient());
};

const getClient = (): FirebaseApp => {
    const current = getApps().find(app => app.name === appName);
    if (current) {
        return current;
    }

    return initializeApp(
        {
            apiKey,
            authDomain
        },
        appName
    );
};
