import classNames from 'classnames';
import { FC, useEffect } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { topNavbarBreakpoint } from '../../../lib/conifg';
import { handleNavbarTransparency } from '../../../lib/helpers/handle_navbar_transparency';
import { SessionState } from '../../../redux/account/state';
import { setNavbarCollapse } from '../../../redux/page/actions';
import { PageSettingsState } from '../../../redux/page/state';
import { RootState } from '../../../redux/root_state';
import { PageRoutesType } from '../../../types/react';
import NavbarTopDropDownMenus from './NavbarTopDropDownMenus';

interface StandardHorizontalNavbarProps {
    pageState: PageSettingsState;
    sessionState: SessionState;
    routes: Array<PageRoutesType>;
    setNavbarCollapse: (shouldCollapse: boolean) => void;
}

const StandardHorizontalNavbar: FC<StandardHorizontalNavbarProps> = (props: StandardHorizontalNavbarProps) => {
    useEffect(() => {
        window.addEventListener('scroll', handleNavbarTransparency);
        return () => window.removeEventListener('scroll', handleNavbarTransparency);
    }, []);

    return (
        <Navbar
            variant={props.pageState.isDark ? 'light' : 'dark'}
            fixed='top'
            expand={topNavbarBreakpoint}
            className={classNames('navbar-standard navbar-theme', {
                'bg-100': !props.pageState.isNavbarCollapsed && props.pageState.isDark,
                'bg-dark': !props.pageState.isNavbarCollapsed && !props.pageState.isDark
            })}
        >
            <Container>
                <Navbar.Brand className='text-white dark__text-white' as={Link} to='/'>
                    <h1>Freedom Fighters 2</h1>
                </Navbar.Brand>
                <Navbar.Toggle onClick={() => props.setNavbarCollapse(!props.pageState.isNavbarCollapsed)} />
                <Navbar.Collapse className='scrollbar'>
                    <Nav>
                        <NavbarTopDropDownMenus routes={props.routes} />
                    </Nav>
                    {/* <TopRight /> */}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        pageState: state.pageSettingsState,
        sessionState: state.sessionState
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setNavbarCollapse: shouldCollapse => dispatch(setNavbarCollapse(shouldCollapse))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StandardHorizontalNavbar);
