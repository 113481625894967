import { FC } from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import StandardHorizontalNavbar from '../components/Landing/Navigation/StandardHorizontalNavbar';
import StandardFooter from '../components/Scaffolding/Footer/StandardFooter';
import loggedInLinks from '../lib/data/routes/logged_in_links';
import loggedOutLinks from '../lib/data/routes/logged_out_links';
import { SessionState } from '../redux/account/state';
import { RootState } from '../redux/root_state';

interface MainLayoutProps {
    sessionState: SessionState;
}

const MainLayout: FC<MainLayoutProps> = (props: MainLayoutProps) => {
    const routes = props.sessionState.sessionActive ? loggedInLinks : loggedOutLinks;

    return (
        <>
            <StandardHorizontalNavbar routes={routes} />
            <Outlet />
            <StandardFooter />
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        sessionState: state.sessionState
    };
};

export default connect(mapStateToProps)(MainLayout);
