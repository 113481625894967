import { WebService } from './web_service';

import paths from '../../../../paths.json';
import { WebRequest } from '../../../../types/shared/web/request';

export class ApiWebService extends WebService<WebRequest> {
    protected buildUrl(request: WebRequest): string {
        const { REACT_APP_API_URL } = process.env;

        let url = `${REACT_APP_API_URL}/${request.path}`;

        if (request.query) {
            // Remove username so it doesn't show up in query params
            let { ...queryParams } = request.query;
            queryParams = Object.fromEntries(Object.entries(queryParams).filter(([_, v]) => v != null));

            const queryPath = new URLSearchParams(queryParams);
            url += `?${queryPath.toString()}`;
        }

        return url;
    }
}

const apiPaths = paths.api;
const apiWebService = new ApiWebService();
export { apiPaths, apiWebService };
