import { WebRequest } from '../../../types/shared/web';
import { adminPaths, adminWebService } from '../web/http/admin';
import { apiPaths, apiWebService } from '../web/http/api';

export const getApiSessionCookie = async (options: {
    email: string;
    idToken: string;
    refreshToken: string;
}): Promise<{ cookie: string; expires: number }> => {
    const request: WebRequest = {
        path: apiPaths.session,
        body: options
    };

    const response = await apiWebService.post<{ cookie: string; expires: number }>(request);

    return response.body;
};

export const getAdminSessionCookie = async (options: {
    email: string;
    idToken: string;
    refreshToken: string;
}): Promise<{ cookie: string; expires: number }> => {
    const request: WebRequest = {
        path: adminPaths.session,
        body: options
    };

    const response = await adminWebService.post<{ cookie: string; expires: number }>(request);

    return response.body;
};
