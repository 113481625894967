export enum LoginSource {
    Default,
    Client,
    OpenAI
}

export const tryGetLoginSource = (type: string | null): LoginSource => {
    switch (type) {
        case LoginSource.Client.toString():
            return LoginSource.Client;
        case LoginSource.OpenAI.toString():
            return LoginSource.OpenAI;
        default:
            return LoginSource.Default;
    }
};
