import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { FC } from 'react';

export interface IconItemProps {
    tag: any;
    icon: any;
    href: string;
    bg: string;
    size: 'sm' | 'lg';
    color: any;
    className: string;
    iconClass: string;
    transform: string;
    onClick: Function;
}

const IconItem: FC<IconItemProps> = function IconItem({
    tag: Tag = 'a',
    icon,
    bg,
    size,
    color,
    className,
    transform,
    iconClass,
    onClick,
    ...rest
}) {
    return (
        <Tag
            className={classNames(className, 'icon-item', {
                [`icon-item-${size}`]: size,
                [`bg-${bg}`]: bg,
                [`text-${color}`]: color
            })}
            {...rest}
            onClick={onClick}
        >
            <FontAwesomeIcon icon={icon} transform={transform} className={iconClass} />
        </Tag>
    );
};
export default IconItem;
