import { FC } from 'react';
import { connect } from 'react-redux';
import { useGlobalStyleEffect } from '../../hooks/Page/UseGlobalStyleEffect';
import { getColor } from '../../lib/helpers/utils';
import { PageSettingsState } from '../../redux/page/state';
import { RootState } from '../../redux/root_state';
import { BasicComponetProps } from '../../types/react/basic_component_props';

interface StyleWrapperProps extends BasicComponetProps {
    pageSettings: PageSettingsState;
}

const StyleWrapper: FC<StyleWrapperProps> = (props: StyleWrapperProps) => {
    useGlobalStyleEffect({
        isDark: props.pageSettings.isDark,
        isFluid: props.pageSettings.isFluid,
        isRTL: props.pageSettings.isRTL,
        navbarPosition: props.pageSettings.navbarPosition,
        isNavbarVerticalCollapsed: props.pageSettings.isNavbarVerticalCollapsed
    });

    if (!props.pageSettings.isLoaded) {
        return (
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    backgroundColor: props.pageSettings.isDark ? getColor('dark') : getColor('light')
                }}
            />
        );
    }

    return <>{props.children}</>;
};

const mapStateToProps = (state: RootState) => {
    return {
        pageSettings: state.pageSettingsState
    };
};

export default connect(mapStateToProps)(StyleWrapper);
