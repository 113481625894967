// App.tsx
import { Chart as ChartJS, registerables } from 'chart.js';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { CloseButton } from './components/Common/Toast';
import StyleWrapper from './components/Wrappers/StyleWrapper.Component';
import store from './redux/store';
import DefaultRoutes from './routes';

const App: React.FC = () => {
    ChartJS.register(...registerables);

    return (
        <Provider store={store}>
            <StyleWrapper></StyleWrapper>
            <Router basename={process.env.PUBLIC_URL}>
                <DefaultRoutes />
                <ToastContainer closeButton={CloseButton} icon={false} position={toast.POSITION.BOTTOM_LEFT} />
            </Router>
        </Provider>
    );
};

export default App;
