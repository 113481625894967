import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { version } from '../../../lib/conifg';
import menuList1 from '../../../lib/data/routes/footer_list';
import { bgWhiteIcons } from '../../../lib/data/social_icons';
import { BasicComponetProps } from '../../../types/react/basic_component_props';
import IconGroup from '../../Common/Icon/IconGroup';
import Section from '../../Common/Section';
import FooterList from './FooterList';

const FooterTitle: FC<BasicComponetProps> = ({ children }) => (
    <h5 className='text-uppercase text-white opacity-85 mb-3'>{children}</h5>
);

const StandardFooter = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    };
    return (
        <>
            <Section bg='dark' className='pt-8 pb-4 light'>
                <div className='position-absolute btn-back-to-top cursor-pointer bg-dark' onClick={scrollToTop}>
                    <FontAwesomeIcon icon='chevron-up' transform='rotate-45' className='text-600' />
                </div>
                <Row>
                    <Col lg={4}>
                        <FooterTitle>Our Mission</FooterTitle>
                        <p className='text-600'>Political brawls: Choose your side and battle in iconic 2D arenas.</p>
                        <IconGroup className='mt-4' icons={bgWhiteIcons} />
                    </Col>
                    <Col className='ps-lg-6 ps-xl-8'>
                        <Row className='mt-5 mt-lg-0'>
                            <Col xs={6} md={3}>
                                <FooterTitle>Company</FooterTitle>
                                <FooterList list={menuList1} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Section>

            <section className=' bg-dark py-0 text-center fs--1 light'>
                <hr className='my-0 border-600 opacity-25' />
                <div className='container py-3'>
                    <Row className='justify-content-between'>
                        <Col xs={12} sm='auto'>
                            <p className='mb-0 text-600'>
                                Copyright
                                <br className='d-sm-none' /> {new Date().getFullYear()} &copy;{' '}
                                <a
                                    className='text-white opacity-85'
                                    href='https://tidalwavemedia.net'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    Tidal Wave Media LLC
                                </a>
                            </p>
                        </Col>
                        <Col xs={12} sm='auto'>
                            <p className='mb-0 text-600'>v{version}</p>
                        </Col>
                    </Row>
                </div>
            </section>
        </>
    );
};

export default StandardFooter;
