import { getTopSellingProducts } from '../../../../lib/services/management/top_selling';
import { RootState } from '../../../root_state';
import { BaseFetchRedux } from '../../fetch_redux';
import { initialBaseFetchState } from '../../initial_state';
import { BaseApiFetchAction } from '../../types';
import { TopSellingProductsState } from './state';

class TopSellingProductsRedux extends BaseFetchRedux<TopSellingProductsState, BaseApiFetchAction> {
    protected key = 'topSellingProducts';
    protected get = getTopSellingProducts;

    getCurrentState(rootState: RootState): TopSellingProductsState {
        return rootState.topSellingProductsState;
    }

    getInitialState(): TopSellingProductsState {
        return {
            ...initialBaseFetchState,
            data: []
        };
    }
}

const topSellingProductsRedux = new TopSellingProductsRedux();

export { topSellingProductsRedux };
