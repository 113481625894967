import { AsyncThunkAction, Reducer, createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { BaseAction } from '../../types/react/redux/base_action';
import { WebResponse } from '../../types/shared/web';
import { logInError } from '../account/login/actions';
import { RootState } from '../root_state';
import { BaseFetchState } from './base_fetch_state';
import { BASE_API_ERROR, BASE_API_FETCH, BASE_API_SUCCESS, BaseApiAction, ThunkRedux } from './types';

export type SetComponentFetch = (isFetching: boolean) => void;

export abstract class BaseFetchRedux<TState extends BaseFetchState & { data: any }, TAction extends BaseApiAction> {
    protected abstract key: string;

    protected abstract readonly get: {
        <TResponse>(): Promise<WebResponse<TResponse>>;
        <TRequest, TResponse>(request: TRequest): Promise<WebResponse<TResponse>>;
    };

    abstract getCurrentState(rootState: RootState): TState;

    abstract getInitialState(): TState;

    protected fetch(): BaseAction {
        return {
            type: `${BASE_API_FETCH}_${this.key}`
        };
    }

    protected error(error: Error): BaseAction {
        return {
            type: `${BASE_API_ERROR}_${this.key}`
        };
    }

    protected success(data: any, ...rest: any): BaseAction {
        return {
            type: `${BASE_API_SUCCESS}_${this.key}`,
            payload: { ...data }
        };
    }

    public createReducer(): Reducer<TState, TAction> {
        const fetchAction = createAction<void>(`${BASE_API_FETCH}_${this.key}`);
        const successAction = createAction<{ body: any; messages: [] }>(`${BASE_API_SUCCESS}_${this.key}`);
        const errorAction = createAction<{ messages: [] }>(`${BASE_API_ERROR}_${this.key}`);

        return createReducer<TState>(this.getInitialState, builder => {
            builder.addCase(fetchAction, state => {
                state.hasErrors = false;
                state.isLoading = true;
                state.success = false;
                state.messages = [];
            });

            builder.addCase(successAction, (state, action) => {
                const { payload } = action;

                state.hasErrors = false;
                state.isLoading = false;
                state.success = true;
                state.messages = payload?.messages;
                state.data = payload?.body;
            });

            builder.addCase(errorAction, (state, action) => {
                state.hasErrors = true;
                state.isLoading = false;
                state.success = false;
                state.messages = action?.payload?.messages;
            });
        });
    }

    public fetchThunk<TRequest>(request?: TRequest): AsyncThunkAction<void, TRequest, ThunkRedux> {
        const thunkCall = createAsyncThunk<void, TRequest, ThunkRedux>(
            `${BASE_API_FETCH}_${this.key}`,
            async (_, thunkAPI) => {
                const { dispatch, getState } = thunkAPI;
                const rootState: RootState = getState();
                const state = this.getCurrentState(rootState);

                if (!state.isLoading) {
                    dispatch(this.fetch());

                    return (!!request ? this.get(request) : this.get())
                        .then(response => {
                            dispatch(this.success(response, request));
                        })
                        .catch(error => {
                            if (error.statusCode == 401) {
                                dispatch(logInError(error));
                            }

                            dispatch(this.error(error));
                        });
                }
            }
        );

        return thunkCall(request);
    }
}
