import { BaseAction } from '../../../types/react/redux/base_action';

export const REGISTER_FETCH = 'REGISTER_FETCH';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export interface RegisterFetchAction extends BaseAction<typeof REGISTER_FETCH> {}

export interface RegisterSuccessAction extends BaseAction<typeof REGISTER_SUCCESS> {}

export interface RegisterFailureAction extends BaseAction<typeof REGISTER_FAILURE> {}

export type RegisterAction = RegisterFetchAction | RegisterSuccessAction | RegisterFailureAction;
