import classNames from 'classnames';
import { FC } from 'react';
import { Container } from 'react-bootstrap';
import { HTMLElementType } from '../../types/react';
import Background from './Background';

export interface ISection extends HTMLElementType {
    fluid?: boolean;
    bg?: string;
    image?: string;
    overlay?: boolean | string;
    position?: string | { x: string; y: string };
    video?: any[];
    bgClassName?: string;
}

const Section: FC<ISection> = function Section({
    fluid = false,
    bg,
    image,
    overlay,
    position,
    video,
    bgClassName,
    className,
    children,
    ...rest
}) {
    const bgProps = { image, overlay, position, video, className };
    bgClassName && (bgProps.className = bgClassName);

    return (
        <section className={classNames({ [`bg-${bg}`]: bg }, className)} {...rest}>
            {image && <Background {...bgProps} />}
            <Container fluid={fluid}>{children}</Container>
        </section>
    );
};

export default Section;
