import { createAsyncThunk } from '@reduxjs/toolkit';
import { IdTokenResult, User, signInWithEmailAndPassword } from 'firebase/auth';
import { Dispatch } from 'redux';
import { getCookieValue } from '../../../lib/helpers/cookie_helpers';
import { getFirebaseAuth } from '../../../lib/helpers/firebase_helpers';
import { getAdminSessionCookie, getApiSessionCookie } from '../../../lib/services/management/session';
import { LoginSource } from '../../../types/login/source';
import { RootState } from '../../root_state';
import { LoginPhase } from './phase';
import {
    LOG_IN_FAILURE,
    LOG_IN_FETCH,
    LOG_IN_GATHERING_TOKENS,
    LOG_IN_INITIALIZING_SESSION,
    LOG_IN_POPUP,
    LOG_IN_SESSION_INITIALIZED,
    LOG_IN_SOURCE_SWITCH,
    LOG_IN_SUCCESS,
    LOG_IN_TOKENS_ACQUIRED,
    LoginAction,
    LoginFailureAction,
    LoginFetchAction,
    LoginGatheringTokensAction,
    LoginInitializingSessionAction,
    LoginPopupAction,
    LoginProviderSwitchAction,
    LoginSessionInitializedAction,
    LoginSuccessAction,
    LoginTokensAcquiredAction,
    POST_LOG_IN,
    PostLoginAction
} from './types';

export const switchLoginSource = (sourceType: LoginSource): LoginProviderSwitchAction => ({
    type: LOG_IN_SOURCE_SWITCH,
    payload: {
        sourceType
    }
});

export const loginFetch = (): LoginFetchAction => ({
    type: LOG_IN_FETCH
});

export const logInSuccess = (user: User): LoginSuccessAction => {
    return {
        type: LOG_IN_SUCCESS,
        payload: {
            user
        }
    };
};

export const postLogin = (): PostLoginAction => {
    return {
        type: POST_LOG_IN
    };
};

export const logInError = (error: { code?: any; statusCode?: number }): LoginFailureAction => {
    let messages = ['There was an error with your login.'];
    switch (error.code) {
        case 'auth/invalid-email':
        case 'auth/user-not-found':
        case 'auth/invalid-password':
            messages.push('Invalid username or password');
            break;
        case 'auth/internal-error':
            messages.push('There was an internal server error. Please reach out to support@salessenseai.com');
            break;
        case 'auth/invalid-email-verified':
            messages.push('The given email has not been verified');
            break;
        case 'auth/operation-not-allowed':
            messages.push('The provider is no longer supported');
            break;
    }

    if (error.statusCode == 401) {
        messages.push('Unauthorized Request');
    }

    return {
        type: LOG_IN_FAILURE,
        payload: {
            messages
        }
    };
};

export const logInPopup = (): LoginPopupAction => ({
    type: LOG_IN_POPUP
});

export const gatheringTokins = (): LoginGatheringTokensAction => ({
    type: LOG_IN_GATHERING_TOKENS,
    payload: {}
});

export const tokensAcquired = (tokenResult: IdTokenResult): LoginTokensAcquiredAction => {
    const expiration = new Date(tokenResult.expirationTime);

    return {
        type: LOG_IN_TOKENS_ACQUIRED,
        payload: { loginToken: tokenResult.token, expiration }
    };
};

export const initializingSession = (): LoginInitializingSessionAction => ({
    type: LOG_IN_INITIALIZING_SESSION
});

export const sessionInitialized = (): LoginSessionInitializedAction => {
    return {
        type: LOG_IN_SESSION_INITIALIZED
    };
};

export const initializeSession = (
    options: {
        email: string;
        idToken: string;
        refreshToken: string;
    },
    dispatch: Dispatch<LoginAction>
) => {
    dispatch(loginFetch());

    return getApiSessionCookie(options)
        .then(sessionResult => {
            dispatch(sessionInitialized());
        })
        .catch(error => {
            dispatch(logInError(error));
        });
};

export const logInWithEmailThunk = createAsyncThunk<
    void,
    { email: string; password: string },
    { dispatch: Dispatch<any>; state: RootState }
>('login', async (request, thunkAPI) => {
    const { dispatch } = thunkAPI;

    const auth = getFirebaseAuth();
    dispatch(loginFetch());

    signInWithEmailAndPassword(auth, request.email, request.password).catch(error => {
        dispatch(logInError(error));
    });
});

export const loginSuccessThunk = createAsyncThunk<void, User, { dispatch: Dispatch<any>; state: RootState }>(
    'login/success',
    async (user, thunkAPI) => {
        const { dispatch, getState } = thunkAPI;
        const sessionState = getState().sessionState;

        if (user == null || sessionState.loginPhase == LoginPhase.GatheringTokens || sessionState.hasErrors) {
            return;
        }

        dispatch(gatheringTokins());
        user.getIdTokenResult().then((tokenResult: IdTokenResult) => {
            dispatch(tokensAcquired(tokenResult));
            dispatch(
                initializeSessionThunk({
                    email: user.email,
                    idToken: tokenResult.token,
                    refreshToken: user.refreshToken
                })
            );
        });
    }
);

const hasSession = (): boolean => {
    const sessionCookie = getCookieValue('Session');

    return sessionCookie != '';
};

export const initializeSessionThunk = createAsyncThunk<
    void,
    { email: string; idToken: string; refreshToken: string },
    { dispatch: Dispatch<any>; state: RootState }
>('login/session', async (request, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const state: RootState = getState();

    if (state.sessionState.loginPhase == LoginPhase.InitializingSession || state.sessionState.hasErrors) {
        return;
    }

    if (hasSession()) {
        dispatch(sessionInitialized());

        return;
    }

    dispatch(initializingSession());

    const apiResult = async () => Promise.resolve(); //getApiSessionCookie(request);
    const adminResult = getAdminSessionCookie(request);

    // Two simultaneous calls will be made to create cookies for each endpoint needed
    Promise.all([apiResult, adminResult])
        .then(sessionResults => {
            dispatch(sessionInitialized());
        })
        .catch(error => {
            dispatch(logInError(error));
        });
});
