import classNames from 'classnames';
import { FC } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import createMarkup from '../../lib/helpers/create_markup';
import { BasicComponetProps } from '../../types/react/basic_component_props';
import Background from './Background';

interface PageHeaderProps extends BasicComponetProps {
    titleTag: 'h1' | 'h2' | 'h3';
}

const PageHeader: FC<PageHeaderProps> = (props: PageHeaderProps) => {
    return (
        <Card>
            <Background
                image={props.content.properties.image}
                className='bg-card d-none d-sm-block'
                style={{
                    borderTopRightRadius: '0.375rem',
                    borderBottomRightRadius: '0.375rem'
                }}
            />
            <Card.Body className='position-relative'>
                <Row>
                    <Col>
                        {props.content.properties.preTitle && (
                            <h6 className='text-600'>{props.content.properties.preTitle}</h6>
                        )}
                        <props.titleTag className='mb-0'>{props.content.properties.title}</props.titleTag>
                        {props.content.properties.description && (
                            <p
                                className={classNames('mt-2', { 'mb-0': !props.content.properties.children })}
                                dangerouslySetInnerHTML={createMarkup(props.content.properties.description)}
                            />
                        )}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default PageHeader;
