export enum LoginPhase {
    Uninitialized,
    LoggedOut,
    LoggedIn,
    LoggingIn,
    LoggingOut,
    Registering,
    Registered,
    GatheringTokens,
    TokensGathered,
    InitializingSession,
    SessionInitialized,
    LoginError,
    PostLogin
}
