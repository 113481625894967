import { BaseAction } from '../../../types/react/redux/base_action';

export const LOG_IN_SOURCE_SWITCH = 'LOG_IN_SOURCE_SWITCH';
export const LOG_IN_FETCH = 'LOG_IN_FETCH';
export const LOG_IN_POPUP = 'LOG_IN_POPUP';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_FAILURE = 'LOG_IN_FAILURE';
export const LOG_IN_GATHERING_TOKENS = 'LOG_IN_GATHERING_TOKENS';
export const LOG_IN_TOKENS_ACQUIRED = 'LOG_IN_TOKENS_ACQUIRED';
export const LOG_IN_INITIALIZING_SESSION = 'LOG_IN_INITIALIZING_SESSION';
export const LOG_IN_SESSION_INITIALIZED = 'LOG_IN_SESSION_INITIALIZED';
export const POST_LOG_IN = 'POST_LOG_IN';

export interface LoginProviderSwitchAction extends BaseAction<typeof LOG_IN_SOURCE_SWITCH> {}

export interface LoginFetchAction extends BaseAction<typeof LOG_IN_FETCH> {}

export interface LoginPopupAction extends BaseAction<typeof LOG_IN_POPUP> {}

export interface LoginSuccessAction extends BaseAction<typeof LOG_IN_SUCCESS> {}

export interface LoginFailureAction extends BaseAction<typeof LOG_IN_FAILURE> {}

export interface LoginGatheringTokensAction extends BaseAction<typeof LOG_IN_GATHERING_TOKENS> {}

export interface LoginTokensAcquiredAction extends BaseAction<typeof LOG_IN_TOKENS_ACQUIRED> {}

export interface LoginInitializingSessionAction extends BaseAction<typeof LOG_IN_INITIALIZING_SESSION> {}

export interface LoginSessionInitializedAction extends BaseAction<typeof LOG_IN_SESSION_INITIALIZED> {}

export interface PostLoginAction extends BaseAction<typeof POST_LOG_IN> {}

export type LoginAction =
    | LoginProviderSwitchAction
    | LoginFetchAction
    | LoginPopupAction
    | LoginSuccessAction
    | LoginFailureAction
    | LoginGatheringTokensAction
    | LoginTokensAcquiredAction
    | LoginInitializingSessionAction
    | LoginSessionInitializedAction
    | PostLoginAction;
