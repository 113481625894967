import { PageRoutesType } from '../../../types/react';

export const about: PageRoutesType = {
    name: 'About',
    icon: 'flag',
    labelDisable: true,
    to: '/about'
};

export default [about];
