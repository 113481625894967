import { PageRoutesType } from '../../../types/react';

export const dashboard: PageRoutesType = {
    name: 'Dashboard',
    icon: 'flag',
    labelDisable: true,
    to: '/dashboard'
};

const metrics: PageRoutesType = {
    name: 'Metrics',
    children: [
        {
            name: 'Sales Metrics',
            active: true,
            icon: 'shopping-cart',
            children: [
                {
                    name: 'By Date',
                    active: true,
                    to: '/dashboard/sales-metrics/by-date'
                },
                {
                    name: 'By Product',
                    active: true,
                    to: '/dashboard/sales-metrics/by-product'
                }
            ]
        },
        {
            name: 'Conversion Rates',
            active: true,
            icon: 'user',
            children: [
                {
                    name: 'By Product',
                    active: true,
                    to: '/dashboard/conversion-rates/by-product'
                },
                {
                    name: 'By Category',
                    active: true,
                    to: '/dashboard/conversion-rates/by-category'
                }
            ]
        }
    ]
};

const catalog: PageRoutesType = {
    name: 'Catalog',
    icon: 'book',
    children: [
        {
            name: 'Categories',
            icon: 'book',
            active: true,
            to: '/catalog'
        },
        {
            name: 'Settings',
            icon: 'edit',
            active: true,
            to: '/catalog/settings'
        }
    ]
};

const settings: PageRoutesType = {
    name: 'Settings',
    children: [
        {
            name: 'Account',
            icon: 'user',
            active: true,
            to: '/settings/account'
        },
        {
            name: 'Organization',
            icon: 'edit',
            active: true,
            to: '/settings/organization'
        },
        {
            name: 'Payment',
            icon: 'tags',
            active: true,
            to: '/settings/payment'
        },
        {
            name: 'Roles',
            icon: 'lock',
            active: true,
            to: '/settings/roles'
        },
        {
            name: 'Store',
            icon: 'globe',
            active: true,
            to: '/settings/store'
        }
    ]
};

export default [dashboard, metrics, catalog, settings];
